import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '@splash/environments/client';

export const appRoutes: Routes = [
	...environment.routes,
	{
		path: '',
		children: [
			{
				path: '',
				redirectTo: 'dashboard',
				pathMatch: 'full',
			},

			{
				path: 'dashboard',
				loadChildren: () =>
					import('./dashboard/login.module').then(
						(m) => m.loginRoutes,
					),
			},
			{
				path: 'view',
				loadChildren: () =>
					import('./views/views.module').then((m) => m.viewsRoutes),
				canActivate: [MsalGuard],
			},
			{
				path: 'auth',
				loadChildren: () =>
					import('./routes/auth.routes').then(
						(m) => m.AuthRoutingModule,
					),
			},
			{
				path: '**',
				redirectTo: 'view',
			},
		],
	},
];
