import { CommonModule } from '@angular/common';
import { Component, NgZone, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
	AuthenticationResult,
	EventMessage,
	EventType,
	InteractionStatus,
} from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { MaterialModule } from './modules/material-module/material-module.module';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [CommonModule, RouterOutlet, MaterialModule],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
	title = 'splash';
	loginDisplay: boolean = false;
	name: string = '';
	private readonly authService = inject(MsalService);
	private readonly msalBroadcastService = inject(MsalBroadcastService);
	private readonly _destroying$ = new Subject<void>();

	constructor(
		private router: Router,
		private zone: NgZone,
	) {}

	ngOnInit(): void {
		this.setLoginDisplay();
		this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

		/**
		 * You can subscribe to MSAL events as shown below. For more info,
		 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
		 */
		this.msalBroadcastService.inProgress$
			.pipe(
				filter(
					(status: InteractionStatus) =>
						status === InteractionStatus.None,
				),
				takeUntil(this._destroying$),
			)
			.subscribe(() => {
				this.setLoginDisplay();
				//this.checkAndSetActiveAccount();
			});

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter(
					(msg: EventMessage) =>
						msg.eventType === EventType.LOGOUT_SUCCESS,
				),
				takeUntil(this._destroying$),
			)
			.subscribe(() => {
				this.setLoginDisplay();
				//this.checkAndSetActiveAccount();
			});

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter(
					(msg: EventMessage) =>
						msg.eventType === EventType.LOGIN_SUCCESS,
				),
				takeUntil(this._destroying$),
			)
			.subscribe((result: EventMessage) => {
				const payload = result.payload as AuthenticationResult;
				this.authService.instance.setActiveAccount(payload.account);
				this.setLoginDisplay();
			});
	}

	setLoginDisplay() {
		// console.log('example',this.authService.instance.getAllAccounts().length )
		this.loginDisplay =
			this.authService.instance.getAllAccounts().length > 0;
		if (this.loginDisplay == true) {
			this.zone.run(() => {
				this.router.navigate(['/view']);
			});
		}
	}
}
