import {
	IPublicClientApplication,
	PublicClientApplication,
} from '@azure/msal-browser';
// import { environment } from '../environment';
import { msalConfig } from './auth.config';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
	const msalInstance = new PublicClientApplication(msalConfig);
	msalInstance.enableAccountStorageEvents();
	return msalInstance;
}
