import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { MsalRedirectComponent } from '@azure/msal-angular';

const appRef = bootstrapApplication(AppComponent, appConfig);

appRef.then((appRef) => appRef.bootstrap(MsalRedirectComponent));

appRef.catch((err) => console.error(err));
