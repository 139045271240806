import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';

import { isPlatformBrowser } from '@angular/common';
import * as client from '@splash/environments/client';
import { Policy } from '@splash/environments/enums/policy.enum';
import { authorityBase } from '@splash/environments/util/authorityBase';

export const constants = {
	additionalStateKeyInStorage: 'additional_state',
	migrationOnDemandEnabledKeyInStorage: 'migration_on_demand_enabled',
};

export class LocalStorageService {
	constructor() {}

	setAdditionalState(state: string): void {
		localStorage.setItem(constants.additionalStateKeyInStorage, state);
	}

	getAdditionalState(): string | null {
		return localStorage.getItem(constants.additionalStateKeyInStorage);
	}

	setMigrationOnDemandEnabled(enabled: boolean): void {
		localStorage.setItem(
			constants.migrationOnDemandEnabledKeyInStorage,
			enabled.toString(),
		);
	}

	getMigrationOnDemandEnabled(): boolean {
		return (
			localStorage.getItem(
				constants.migrationOnDemandEnabledKeyInStorage,
			) == 'true'
		);
	}

	clear() {
		localStorage.removeItem(constants.additionalStateKeyInStorage);
	}
}

export const b2cPolicies = {
	names: {
		signUpSignIn: client.b2c.policies[Policy.SignUpSignIn],
		resetPassword: client.b2c.policies[Policy.ResetPassword],
		editProfile: client.b2c.policies[Policy.EditProfile],
	},
	authorities: {
		signUpSignIn: {
			authority: `${authorityBase(client.b2c)}/${client.b2c.policies[Policy.SignUpSignIn]}`,
		},
		resetPassword: {
			authority: `${authorityBase(client.b2c)}/${client.b2c.policies[Policy.ResetPassword]}`,
		},
		editProfile: {
			authority: `${authorityBase(client.b2c)}/${client.b2c.policies[Policy.EditProfile]}`,
		},
	},
	authorityDomain: client.b2c.authorityDomain,
};

const isIE: boolean = false;
// eslint-disable-next-line @typescript-eslint/ban-types
export const authconf = (platformId: Object) => {
	if (isPlatformBrowser(platformId))
		window.navigator.userAgent.indexOf('MSIE ') > -1 ||
			window.navigator.userAgent.indexOf('Trident/') > -1;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const migrationOnDemandEnabled =
		new LocalStorageService().getMigrationOnDemandEnabled();
};

// const clientId = client.b2c.clientId;

export const msalConfig: Configuration = {
	auth: {
		clientId: client.b2c.clientId, // This is the ONLY mandatory field that you need to supply.
		authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
		knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
		redirectUri: client.b2c.redirects.login, // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
		postLogoutRedirectUri: client.b2c.redirects.logout, // Points to window.location.origin by default.
		//journey: 'registration',
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
	},
	system: {
		/**
		 * Below you can configure MSAL.js logs. For more information, visit:
		 * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
		 */
		allowRedirectInIframe: true,
		loggerOptions: {
			//loggerCallback(logLevel: LogLevel, message: string) {},
			logLevel: client.b2c.logLevel,
			piiLoggingEnabled: false,
		},
		tokenRenewalOffsetSeconds: 300, // Sets the window of offset needed to renew the token before expiry. E.g., if acquireTokenSilent() is called within 300 seconds of tokens expiring, the call will attempt to refresh the tokens
	},
};

export const loginRequest = {
	scopes: [client.b2c.scope],
};
