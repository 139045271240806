import { ApplicationConfig, importProvidersFrom } from '@angular/core';

import {
	HTTP_INTERCEPTORS,
	provideHttpClient,
	withFetch,
	withInterceptorsFromDi,
} from '@angular/common/http';
import {
	BrowserModule,
	provideClientHydration,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import {
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalBroadcastService,
	MsalGuard,
	MsalInterceptor,
	MsalService,
} from '@azure/msal-angular';
import { environment } from '../environments/client';
import { appRoutes } from './app-routing.module';
import { MaterialModule } from './modules/material-module/material-module.module';
import { MSALGuardConfigFactory } from './msal-guard.factory';
import { MSALInstanceFactory } from './msal-instance.factory';
import { MSALInterceptorConfigFactory } from './msal-interceptor-config.factory';

export const appConfig: ApplicationConfig = {
	providers: [
		provideHttpClient(withFetch(), withInterceptorsFromDi()),
		provideRouter(appRoutes),
		importProvidersFrom(BrowserModule, MaterialModule),
		MsalService,
		MsalBroadcastService,
		MsalGuard,
		{
			provide: HTTP_INTERCEPTORS, // Provides as HTTP Interceptor
			useClass: MsalInterceptor,
			multi: true,
		},
		/*
			Use the FakeSplashLoginService to quickly test
			inter-frame communication.  Use the eform-event-simulator
			app to simulate the child app frame. `npm run sim:dev`
		*/
		// {
		// 	provide: SplashLoginService,
		// 	useClass: FakeSplashLoginService,
		// },

		// {
		// 	provide: HTTP_INTERCEPTORS,
		// 	useFactory: () => {
		// 		const interceptor: HttpInterceptor = {
		// 			intercept(req: HttpRequest<any>, next: HttpHandler) {
		// 				const started = Date.now();
		// 				let ok: string;
		// 				const jwt = req.headers
		// 					.get('Authorization')
		// 					?.split(' ')[1]
		// 					?.split('.')
		// 					.slice(0, 2)
		// 					.map((x) => atob(x))
		// 					.map((x) => JSON.parse(x));
		// 				// extend server response observable with logging
		// 				return next.handle(req).pipe(
		// 					tap({
		// 						// Succeeds when there is a response; ignore other events
		// 						next: (event) =>
		// 							(ok =
		// 								event instanceof HttpResponse
		// 									? 'succeeded'
		// 									: ''),
		// 						// Operation failed; error is an HttpErrorResponse
		// 						error: (_error) => (ok = 'failed'),
		// 					}),
		// 					// Log when response observable either completes or errors
		// 					finalize(() => {
		// 						const elapsed = Date.now() - started;

		// 						console.log({ jwt });
		// 						const msg = `${req.method} "${req.urlWithParams}" ${ok} in ${elapsed} ms.`;
		// 						console.log(msg);
		// 					}),
		// 				);
		// 			},
		// 		};
		// 		return interceptor;
		// 	},
		// 	multi: true,
		// },
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		...environment.telemetry,
		provideAnimations(),
		provideClientHydration(),
	],
};
