import { MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { protectedResourceMap } from '@splash/environments/client';

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};

	// const protectedResourceMap = new Map<string, Array<string>>([
	// 	[
	// 		'https://jsonplaceholder.typicode.com/todos',
	// 		[
	// 			'openid',
	// 			'profile',
	// 			'https://testulplatform.onmicrosoft.com/api/read',
	// 		],
	// 	],
	// ]);

	/**BASIC IMPLEMENTATION OF PASSING BEARER TOKEN TO HEADERS AUTOMATICALLY */
	// const protectedResourceMap = new Map<string, Array<string>>([
	// 	//['https://graph.microsoft.com/v1.0/me', ['user.read']],
	// 	[
	// 		'/splash/',
	// 		[
	// 			'https://testulplatform.onmicrosoft.com/splash-local/Read',
	// 			//'https://testulplatform.onmicrosoft.com/splash-local/Write'
	// 		],
	// 	],
	// ]);

	// // Object.entries(environment.azure.b2c.protectedResources).forEach(
	// //  ([, request]) => {
	// //      protectedResourceMap.set(request.endpoint, request.scopes);
	// //  },
	// // );

	// return {
	// 	interactionType: InteractionType.Popup,
	// 	protectedResourceMap,
	// };
}
